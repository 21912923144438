import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { deleteJson } from "utils/fetch";

import logo from "images/nav-logo.svg";
import externalIcon from "images/icons/external.inline.svg";

import Hamburger from "./components/hamburger";

import css from "./index.module.css";

const Navigation = ({
  isMenuOpen,
  isLoggedIn,
  yourProfilePath,
  onMenuClick,
}) => {
  const rootClasses = cx(
    "z-5 flex flex-shrink-0 items-center flex-column f2 f5-l flex-row-l justify-left-l bg-purple white b",
    isMenuOpen && css.menuIsOpen,
    css.root,
  );

  const siteNavClasses = cx(
    "tc tl-l flex-grow-1 bg-not-white bg-purple-l purple white-l pa2",
    css.siteNav,
  );

  const userNavClasses = cx("tc tr-l pa2", css.userNav);

  const navClasses = cx(
    "flex-grow-1 flex-shrink-0 flex-column-reverse flex-row-l w-100 w-auto-l bg-purple",
    css.nav,
  );

  const handleLogout = async (e) => {
    e.preventDefault();

    await deleteJson("/logout");
    window.location.assign("/");
  };

  const logoTag = <img src={logo} className="ml2-l mr4-l pa2" />;

  return (
    <nav className={rootClasses}>
      <Hamburger onClick={onMenuClick} isActive={isMenuOpen} />
      {isLoggedIn ? (
        <a className="lh-0" href="/local-families">
          {logoTag}
        </a>
      ) : (
        <a className="lh-0" href="/">
          {logoTag}
        </a>
      )}

      <div className={navClasses}>
        <div className={siteNavClasses}>
          <ul className="list">
            <li>
              <a href="https://www.korukids.co.uk">
                Main Koru Kids site{" "}
                <span
                  className={css.external}
                  dangerouslySetInnerHTML={{ __html: externalIcon }}
                />
              </a>
            </li>
          </ul>
        </div>
        <div className={userNavClasses}>
          {isLoggedIn ? (
            <ul className="list">
              <li>
                <a href="/conversations">Messages</a>
              </li>
              <li>
                <a href="/your-requirements">Your requirements</a>
              </li>
              <li>
                <a href="/local-families">Show matching families</a>
              </li>
              <li>
                <a href={yourProfilePath}>Your profile</a>
              </li>
              <li>
                <a href="/logout" onClick={handleLogout}>
                  Log Out
                </a>
              </li>
            </ul>
          ) : (
            <ul className="list">
              <li>
                <a href="/login">Log In</a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  yourProfilePath: PropTypes.string,
};

export default Navigation;
