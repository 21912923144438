import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Button from "components/button";
import FamilyProperties from "components/family-properties";

import bioIcon from "images/icons/bio.svg";

import css from "./index.module.css";

const downcaseFirst = (input) =>
  input.charAt(0).toLowerCase() + input.substr(1);

const FamilyCard = ({
  familyName,
  hashid,
  isReferrer,
  lastActive,
  profilePath,
  profileLinkReferral,
  ...rest
}) => {
  let profileUrl = profilePath;

  if (profileLinkReferral) {
    profileUrl += `?ref=${profileLinkReferral}`;
  }

  const handleClick = (e) => {
    e.preventDefault();
    window.location.assign(profileUrl);
  };

  return (
    <div
      className="pointer w-100 pt0-ns pb3-ns pa4-ns pa2"
      onClick={handleClick}
    >
      <div
        className={cx(
          css.boxShadow,
          "b--light-gray ba br2 h-100 flex flex-column",
        )}
      >
        {isReferrer && (
          <div className="bg-not-white flex items-center ph3 pv2">
            <div className="flex-shrink-0 pr3">
              <img src={bioIcon} className={css.image} />
            </div>
            <div className="medium-gray">
              <div className="b f6 mb1">This family invited you</div>
              <div className="f7 lh-title">
                You responded to {downcaseFirst(familyName)}’s invite when you
                signed up
              </div>
            </div>
          </div>
        )}
        <div className="tl pa3 f4-ns f5 lh-copy purple flex-grow-1">
          <h3 className="b f2 normal">{familyName}</h3>
          <div className="f7 silver">Last active {lastActive}</div>
          <FamilyProperties {...rest} />
        </div>
        <div className="tr ph3 pb3">
          <Button component="a" href={profileUrl} className="ph4 f5-ns f6">
            View profile
          </Button>
        </div>
      </div>
    </div>
  );
};

FamilyCard.propTypes = {
  familyName: PropTypes.string.isRequired,
  hashid: PropTypes.string.isRequired,
  isReferrer: PropTypes.bool.isRequired,
  lastActive: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  profilePath: PropTypes.string.isRequired,
  profileLinkReferral: PropTypes.string,
};

export default FamilyCard;
