import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Message = ({ body, selfAuthored, isPending = false }) => {
  const rootClass = cx("mb1 flex w-75", selfAuthored && "justify-end");
  const bodyClass = cx(
    "pv1 ph2 br2 ba bw1 b--light-gray dark-gray f6 lh-copy dib break-word pre-line",
    selfAuthored && "bg-light-gray",
    isPending && "o-50",
  );

  return (
    <div className={rootClass}>
      <div className={bodyClass}>{body}</div>
    </div>
  );
};

Message.propTypes = {
  body: PropTypes.string.isRequired,
  selfAuthored: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
};

export default Message;
