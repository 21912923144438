import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import useUniqueId from "hooks/use-unique-id";

import css from "./index.module.css";

const Input = ({
  className,
  inputClassName,
  error,
  field = {},
  id,
  label,
  component: InputComponent = "input",
  name = field.name,
  value = field.value,
  onChange = field.onChange,
  onBlur = field.onBlur,
  ...rest
}) => {
  const inputId = useUniqueId(id);
  const [active, setActive] = useState(value && value.length !== 0);

  const rootClasses = cx(
    className,
    css.root,
    active && css.active,
    error && css.error,
  );
  const labelClasses = cx("pv1 mb1 silver", css.label);
  const inputClasses = cx(
    "outline-0 input-reset input w-100 bb b--light-gray",
    css.input,
    inputClassName,
  );

  const handleBlur = (e) => {
    setActive(value.length !== 0);
    onBlur(e);
  };

  const handleFocus = () => {
    setActive(true);
  };

  const { form: _, ...inputRest } = rest;

  return (
    <div className={rootClasses}>
      <label className={labelClasses} htmlFor={inputId}>
        {label}
      </label>
      <InputComponent
        className={inputClasses}
        id={inputId}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...inputRest}
      />
      {error && <div className="f6 mt1 pt1 orange">{error}</div>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  component: PropTypes.elementType,
  error: PropTypes.string,
  field: PropTypes.object,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default Input;
