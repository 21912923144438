import React, { useState } from "react";
import PropTypes from "prop-types";
import copy from "clipboard-copy";

import { trackPageView } from "utils/analytics";

import ActionFlowContainer from "components/action-flow-container";
import Button from "components/button";

import whatsAppIcon from "images/whatsapp.svg";
import facebookIcon from "images/facebook.png";

const { FACEBOOK_APP_ID } = process.env;

const SharingScreen = ({ shareText, shareUrl, redirectUrl }) => {
  const [hasCopied, setCopied] = useState(false);

  const shareTextWithUrl = `${shareText} ${shareUrl}`;

  const copyShareText = async (e) => {
    e.preventDefault();

    await copy(shareTextWithUrl);
    setCopied(true);
  };

  const innerButtonClasses = "flex items-center justify-center";

  const buttonProps = {
    className: "f6 w-100 mt2 b--purple",
    style: "outline",
    component: "a",
    target: "_blank",
    rel: "noopener noreferrer",
  };

  const whatsAppShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    shareTextWithUrl,
  )}`;

  const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=page&href=${encodeURIComponent(
    shareUrl,
  )}&redirect_uri=${encodeURIComponent(redirectUrl)}&quote=${encodeURIComponent(
    shareText,
  )}`;

  const handleFacebookClick = () => {
    trackPageView("/share/shared/facebook");
  };

  const handleWhatsAppClick = () => {
    trackPageView("/share/shared/whatsapp");
  };

  return (
    <div className="mw6 center h-100">
      <ActionFlowContainer
        title="Find a family in your network"
        description="Try posting a short message on your friendship groups and other local pages to help find other families in your area."
        backTo={{ href: "/local-families", push: false }}
      >
        <div className="medium-gray f6 lh-copy br2 ba b--moon-gray pa3 pb2 b mb3">
          <div>{shareTextWithUrl}</div>
          <div className="tr mt2">
            <a
              role="button"
              className="no-underline pointer"
              onClick={copyShareText}
            >
              {hasCopied ? "✔ " : null}Copy text
            </a>
          </div>
        </div>
        <Button
          href={whatsAppShareUrl}
          onClick={handleWhatsAppClick}
          {...buttonProps}
        >
          <div className={innerButtonClasses}>
            <img className="mr2" src={whatsAppIcon} /> Post on WhatsApp
          </div>
        </Button>
        <Button
          href={facebookShareUrl}
          onClick={handleFacebookClick}
          {...buttonProps}
        >
          <div className={innerButtonClasses}>
            <img className="mr2" width="24" height="24" src={facebookIcon} />{" "}
            Post on Facebook
          </div>
        </Button>
        <small className="mv3 db gray lh-title">
          We will never post anything on your behalf on social media. You will
          have a chance to review the post and confirm before posting.
        </small>
      </ActionFlowContainer>
    </div>
  );
};

SharingScreen.propTypes = {
  shareText: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

export default SharingScreen;
