import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { isEmpty } from "ramda";
import * as Yup from "yup";
import uniqid from "uniqid";

import { postJson } from "utils/fetch";
import transformErrors from "utils/transform-errors";

import Form from "./components/form";

const SignupSchema = Yup.object().shape({
  children: Yup.array().of(
    Yup.object()
      .shape({
        gender: Yup.string().required("Please provide your child's gender"),
      })
      .test("dateOfBirth", function test(values) {
        const { day, month, year } = values;

        if (day && month && year) {
          return true;
        }

        return this.createError({
          path: `${this.path}dob`,
          message: "Please provide your child's date of birth or due date",
        });
      }),
  ),
});

const ChildScreen = ({
  onSubmit,
  backTo,
  submitLabel = "Next",
  initialChildren = [{ tempId: uniqid(), pickupNotes: "" }],
}) => {
  return (
    <Formik
      initialValues={{
        children: initialChildren,
      }}
      validationSchema={SignupSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async ({ children }, { setErrors, setSubmitting }) => {
        const { errors = {} } = await postJson("/api/children", {
          children: children.map(
            ({ hashid, year, month, day, gender, pickupNotes }) => ({
              hashid,
              dateOfBirth: `${year}-${month}-${day}`,
              gender,
              pickupNotes,
            }),
          ),
        });

        setSubmitting(false);

        if (isEmpty(errors)) {
          onSubmit();
        } else {
          setErrors(transformErrors(errors));
        }
      }}
    >
      {(formikProps) => (
        <Form backTo={backTo} submitLabel={submitLabel} {...formikProps} />
      )}
    </Formik>
  );
};

ChildScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.object,
  initialChildren: PropTypes.array,
  submitLabel: PropTypes.string,
};

export default ChildScreen;
