import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isSameDay } from "date-fns/fp";
import { groupWith } from "ramda";
import cx from "classnames";

import MessagesByDate from "../messages-by-date";

import css from "./index.module.css";

const groupByDay = groupWith((message, next) =>
  isSameDay(message.sentAt, next.sentAt),
);

const MessagesList = ({ className, messages }) => {
  const containerRef = useRef();

  const messagesByDay = groupByDay(messages);
  const rootClasses = cx(className, css.root);

  useEffect(() => {
    window.scrollTo({
      top: containerRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [messages]);

  return (
    <div className={rootClasses} ref={containerRef} data-testid="conversation">
      {messagesByDay.map((dailyMessages, i) => {
        const { hashid, sentAt } = dailyMessages[0];

        return (
          <MessagesByDate
            className="mh2"
            key={`ml-${hashid}-${i}`}
            date={sentAt}
            messages={dailyMessages}
          />
        );
      })}
    </div>
  );
};

MessagesList.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array,
};

export default MessagesList;
