import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { equals, filter, includes, isEmpty, keys } from "ramda";

import { postJson } from "utils/fetch";
import transformErrors from "utils/transform-errors";

import Form from "./components/form";

const SignupSchema = Yup.object()
  .shape({
    maxDaysCare: Yup.number()
      .when("minDaysCare", {
        is: (val) => val,
        then: Yup.number().min(
          Yup.ref("minDaysCare"),
          "Your maximum number of days is less than your minimum number of days",
        ),
      })
      .when("unsureNumDaysCare", {
        is: (val) => !val,
        then: Yup.number().required(
          "Please let us know the maximum number of days you need care",
        ),
      }),
    minDaysCare: Yup.number().when("unsureNumDaysCare", {
      is: (val) => !val,
      then: Yup.number().required(
        "Please let us know the minimum number of days you need care",
      ),
    }),
  })
  .test("minSelection", function test(values) {
    const { minDaysCare, days, unsureDays } = values;

    if (minDaysCare && !unsureDays) {
      const selectedDayCount = Object.keys(
        filter(includes("yes"), days),
      ).length;

      if (selectedDayCount < minDaysCare) {
        return this.createError({
          path: "minDaysCare",
          message:
            "Your minimum number of days is more than the number of days chosen",
        });
      }
    }

    return true;
  })
  .test("selectOneIfUnsure", function test(values) {
    const { days, unsureNumDaysCare, unsureDays } = values;

    if (unsureNumDaysCare && !unsureDays) {
      const selectedDayCount = Object.keys(
        filter(includes("yes"), days),
      ).length;

      if (selectedDayCount < 1) {
        return this.createError({
          message: "Please select at least one day you need care",
        });
      }
    }

    return true;
  });

const DaysScreen = ({
  onSubmit,
  backTo,
  initialMonday = "no",
  initialTuesday = "no",
  initialWednesday = "no",
  initialThursday = "no",
  initialFriday = "no",
  initialSaturday = "no",
  initialSunday = "no",
  initialMinDaysCare = "",
  initialMaxDaysCare = "",
  initialUnsureNumDaysCare = false,
  initialUnsureDays = false,
  submitLabel = "Next",
}) => {
  return (
    <Formik
      initialValues={{
        minDaysCare: initialMinDaysCare,
        maxDaysCare: initialMaxDaysCare,
        days: {
          monday: initialMonday,
          tuesday: initialTuesday,
          wednesday: initialWednesday,
          thursday: initialThursday,
          friday: initialFriday,
          saturday: initialSaturday,
          sunday: initialSunday,
        },
        unsureNumDaysCare: initialUnsureNumDaysCare,
        unsureDays: initialUnsureDays,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignupSchema}
      onSubmit={async ({ days, ...values }, { setErrors, setSubmitting }) => {
        const { errors = {} } = await postJson("/api/days", {
          days: {
            essentialDays: keys(filter(equals("yes_essential"), days)),
            preferableDays: keys(filter(equals("yes_preferable"), days)),
            ...values,
          },
        });

        setSubmitting(false);

        if (isEmpty(errors)) {
          onSubmit();
        } else {
          setErrors(transformErrors(errors));
        }
      }}
    >
      {(formikProps) => (
        <Form backTo={backTo} submitLabel={submitLabel} {...formikProps} />
      )}
    </Formik>
  );
};

DaysScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.object,
  initialMonday: PropTypes.string,
  initialTuesday: PropTypes.string,
  initialWednesday: PropTypes.string,
  initialThursday: PropTypes.string,
  initialFriday: PropTypes.string,
  initialSaturday: PropTypes.string,
  initialSunday: PropTypes.string,
  initialMinDaysCare: PropTypes.string,
  initialMaxDaysCare: PropTypes.string,
  initialUnsureNumDaysCare: PropTypes.bool,
  initialUnsureDays: PropTypes.bool,
  submitLabel: PropTypes.string,
};

export default DaysScreen;
