import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "ramda";
import { format, setDate, addMonths } from "date-fns/fp";

import { postJson } from "utils/fetch";
import scrollToTop from "utils/scroll-to-top";
import transformErrors from "utils/transform-errors";

import Form from "./components/form";

const valueFormat = format("yyyy-MM-dd");

const NOW = () => setDate(1, new Date());

const INITIAL_DATE = () => valueFormat(addMonths(6, NOW()));
const INITIAL_MIN_DATE = () => valueFormat(addMonths(4, NOW()));
const INITIAL_MAX_DATE = () => valueFormat(addMonths(8, NOW()));

const SignupSchema = Yup.object().shape({
  startDateMin: Yup.string().test(
    "startEarlier",
    "Please make sure your earlier date is before your preferred start date",
    function test() {
      const { startEarlier, startDateMin, startDate } = this.parent;
      if (startEarlier) {
        return new Date(startDate) >= new Date(startDateMin);
      }

      return true;
    },
  ),
  startDateMax: Yup.string().test(
    "startLater",
    "Please make sure your later date is after your preferred start date",
    function test() {
      const { startLater, startDateMax, startDate } = this.parent;
      if (startLater) {
        return new Date(startDate) <= new Date(startDateMax);
      }

      return true;
    },
  ),
});

const StartDateScreen = ({
  onSubmit,
  backTo,
  initialStartDate = INITIAL_DATE(),
  initialStartEarlier = true,
  initialStartDateMin = INITIAL_MIN_DATE(),
  initialStartLater = true,
  initialStartDateMax = INITIAL_MAX_DATE(),
  submitLabel = "Next",
}) => {
  return (
    <Formik
      initialValues={{
        startDate: initialStartDate,
        startEarlier: initialStartEarlier,
        startDateMin: initialStartDateMin,
        startLater: initialStartLater,
        startDateMax: initialStartDateMax,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignupSchema}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        const { errors = {} } = await postJson("/api/start_date", {
          startDate: {
            startDateMin: values.startEarlier
              ? values.startDateMin
              : values.startDate,
            startDate: values.startDate,
            startDateMax: values.startLater
              ? values.startDateMax
              : values.startDate,
          },
        });

        setSubmitting(false);

        if (isEmpty(errors)) {
          onSubmit();
        } else {
          setErrors(transformErrors(errors));
          scrollToTop();
        }
      }}
    >
      {(formikProps) => (
        <Form backTo={backTo} submitLabel={submitLabel} {...formikProps} />
      )}
    </Formik>
  );
};

StartDateScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.object,
  initialStartDate: PropTypes.string,
  initialStartEarlier: PropTypes.bool,
  initialStartDateMin: PropTypes.string,
  initialStartLater: PropTypes.bool,
  initialStartDateMax: PropTypes.string,
  submitLabel: PropTypes.string,
};

export default StartDateScreen;
