import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { format } from "date-fns/fp";

import Message from "./message";

const MessagesByAuthor = ({ messages }) => {
  const lastMessage = messages[messages.length - 1];
  const { sentAt, selfAuthored } = lastMessage;
  const formattedSentAt = format("HH:mm", sentAt);

  const rootClass = cx(
    "ph2 mb2 flex flex-wrap",
    selfAuthored && "flex-row-reverse",
  );
  const timeClasses = cx("pb1 f7 w-25 self-end gray", !selfAuthored && "tr");

  return (
    <div className={rootClass}>
      {messages.map((message) => (
        <Message key={message.hashid} {...message} />
      ))}
      <div className={timeClasses}>{formattedSentAt}</div>
    </div>
  );
};

MessagesByAuthor.propTypes = {
  messages: PropTypes.array,
};

export default MessagesByAuthor;
