const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

const buildTransformKeyFunction = (transform) => {
  const transformKeys = (object) => {
    if (Array.isArray(object)) return object.map(transformKeys);
    if (!isObject(object)) return object;

    return Object.keys(object).reduce(
      (accumulator, key) =>
        Object.assign(accumulator, {
          [transform(key)]: transformKeys(object[key]),
        }),
      {},
    );
  };

  return transformKeys;
};

export const camelCase = (string) =>
  string.replace(/[_-](\w|$)/g, (m, p1) => p1.toUpperCase());

export const snakeCase = (string) =>
  string
    .replace("-", "_")
    .replace(/([a-z0-9])([A-Z])/g, (m, p1, p2) => `${p1}_${p2}`)
    .toLowerCase();

export const camelCaseKeys = buildTransformKeyFunction(camelCase);
export const snakeCaseKeys = buildTransformKeyFunction(snakeCase);
