import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";
import { mergeAll, chain, values } from "ramda";

import css from "./index.module.css";

const FormErrors = ({ className, errors }) => {
  const rootClasses = cx("pa2 ba br2 medium-gray", className, css.root);

  const displayableErrors = chain(
    (value) => (Array.isArray(value) ? values(mergeAll(value)) : value),
    values(errors),
  );

  return displayableErrors.length ? (
    <div className={rootClasses}>
      <div className="b">There was a problem with your details</div>
      <ul className="ml3 mt1 lh-copy">
        {displayableErrors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  ) : null;
};

FormErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default FormErrors;
